<template>
  <section
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor})`"
  >
    <div
      class="tw-flex tw-max-w-xl tw-items-center tw-flex-col-reverse tw-gap-6 lg:tw-max-w-none lg:tw-flex-row lg:tw-gap-16 xl:tw-gap-32 tw-text-black"
      :class="{ 'lg:tw-flex-row-reverse': vars.isReversedBool }"
    >
      <div class="tw-relative tw-flex-[1_1_50%]"
      :class="{'tw-mr-10' : vars.thumbnailCornerText === 'bottom-right' || 'top-right'},
              {'tw-ml-10' : vars.thumbnailCornerText === 'bottom-left' || 'top-left'},
              {'tw-mt-8 lg:tw-mt-0' : vars.thumbnailCornerText === 'top-left' || 'top-right'}">
        <NuxtImg
          v-if="vars.photoImage && !vars.videoUrl"
          :src="vars.photoImage"
          class="tw-h-auto tw-w-full "
        />
        <library-video-embed v-if="vars.videoUrl"
          :video-url="vars.videoUrl"
          title="Video Popup"
          :is-popup="true"
          :cover-photo="vars.photoImage ? vars.photoImage : null"
        />
        <NuxtImg
          v-if="vars.thumbnailImage"
          :src="vars.thumbnailImage"
          class="tw-absolute tw-h-auto tw-w-1/2 tw-transform"
          :class="{'tw-translate-y-1/4 tw-border-r-8 tw-border-t-8 -translate-x-1/4 -tw-left-10 tw-bottom-0': vars.thumbnailCornerText === 'bottom-left'},
                  {'tw-translate-y-1/4 tw-border-l-8 tw-border-t-8 -tw-right-10 -translate-x-1/4 tw-bottom-0': vars.thumbnailCornerText === 'bottom-right'},
                  {'tw-translate-y-1/4 tw-border-r-8 tw-border-b-8 -translate-x-1/4 -tw-left-10 -tw-top-1/4': vars.thumbnailCornerText === 'top-left'},
                  {'tw-translate-y-1/4 tw-border-l-8 tw-border-b-8 -tw-right-10 -translate-x-1/4 -tw-top-1/4': vars.thumbnailCornerText === 'top-right'}"
          :style="`border-color: var(--c-${vars.backgroundColor})`"
        />
      </div>
      <div class="tw-flex tw-flex-[1_1_50%] tw-flex-col tw-gap-3 lg:tw-gap-6">
        <div v-html="vars.titleHtml" class="tw-flex tw-flex-row tw-flex-wrap"></div>
        <div v-if="descriptions" class="tw-flex tw-flex-col tw-gap-4">
          <p
            v-for="(item, index) in descriptions"
            :key="`infobox-description-${index}`"
            class="tw-max-w-2xl"
          >
            {{ item.description }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'InfoBox',

  mixins: [ComponentMixin],

  computed: {
    descriptions() {
      return this.groupedVariables.descriptions;
    },
  },
});
</script>

