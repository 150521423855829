<template>
  <section class="n-section-primary tw-bg-cover tw-bg-center" :style="`background-image: url(${vars.backgroundImage})`">
    <div class="tw-absolute tw-inset-0 tw-opacity-80" :style="`background-color: var(--c-secondary);`"></div>
    <div class="tw-z-10 tw-w-full tw-space-y-5">
      <div class="tw-flex tw-w-full tw-justify-center">
        <div
          class="tw-flex tw-flex-wrap tw-justify-center tw-gap-3 tw-gap-y-0 tw-text-center lg:tw-gap-4"
          v-html="vars.titleHtml"
        ></div>
      </div>
      <div class="tw-grid tw-grid-cols-4 tw-gap-3 lg:tw-grid-cols-8 xl:tw-grid-cols-12">
        <div
          v-for="(item, index) in cards"
          :key="`marketingreviews-card-${index}`"
          class="n-card tw-col-span-4 tw-flex tw-w-full tw-flex-col tw-justify-between tw-space-y-5 tw-rounded tw-bg-white tw-p-10 tw-pt-10 tw-text-center"
        >
          <div class="tw-flex tw-flex-col tw-items-center tw-space-y-3 lg:tw-min-h-36">
            <NuxtImg :src="item.icon" :alt="item.title" :width="75" :height="75" />
            <h4 :title="item.title" style="color: var(--c-text_black)">
              {{ item.title }}
            </h4>
          </div>
          <div class="tw-h-full">
            <p style="color: var(--c-text_black)">
              {{ item.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'MarketingReviews',

  mixins: [ComponentMixin],

  computed: {
    cards() {
      return this.groupedVariables.cards;
    },
  },
});
</script>
