<template>
  <section class="tw-h-[100vh]">
    <video
      v-if="isMobileDevice && vars.mobileBackgroundVideo"
      :src="vars.mobileBackgroundVideo"
      class="tw-absolute tw-left-0 tw-top-0 tw-h-full tw-w-full tw-object-cover"
      autoplay
      loop
      muted
      playsinline
    ></video>
    <video
      v-else-if="vars.backgroundVideo"
      :src="vars.backgroundVideo"
      class="tw-absolute tw-left-0 tw-top-0 tw-h-full tw-w-full tw-object-cover"
      autoplay
      loop
      muted
      playsinline
    ></video>
    <div class="tw-absolute tw-inset-0 tw-bg-black tw-opacity-50"></div>
    <div class="tw-relative tw-z-10 tw-flex tw-h-full tw-items-end tw-justify-center">
      <div
        class="tw-z-100 tw-mx-2 tw-my-4 tw-flex tw-flex-col tw-gap-2 tw-text-center md:tw-mx-12 md:tw-my-12 xl:tw-gap-6"
      >
        <h1 class="hero-title tw-text-white">
          {{ vars.titleText }}
        </h1>
        <div class="tw-m-10 tw-flex tw-flex-wrap tw-items-center tw-justify-center tw-gap-3 lg:tw-gap-5">
          <nuxt-link
            :external="true"
            v-for="(button, index) in buttons"
            :key="`herosection-button-${index}`"
            :to="button.link"
            :class="`n-${button.type}`"
            >{{ button.label }}</nuxt-link
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'HeroSection',

  mixins: [ComponentMixin],

  computed: {
    buttons() {
      return this.groupedVariables.buttons;
    },
  },
});
</script>
