<template>
  <section class="n-section-primary" :style="`background-color: var(--c-${vars.backgroundColor})`">
    <div
      class="tw-grid tw-max-w-xl tw-grid-cols-1 tw-gap-10 xl:tw-max-w-none xl:tw-grid-cols-12 xl:tw-gap-x-20"
      style="color: var(--c-text-primary)"
    >
    <div class="tw-relative xl:tw-col-span-5 xl:tw-row-span-2 tw-self-center tw-object-cover"
      :class="{'tw-mr-10' : vars.thumbnailCornerText === 'bottom-right' || 'top-right'},
              {'tw-ml-10' : vars.thumbnailCornerText === 'bottom-left' || 'top-left'},
              {'tw-mt-8 lg:tw-mt-0' : vars.thumbnailCornerText === 'top-left' || 'top-right'},
              {'xl:tw-col-start-1 xl:tw-row-start-1': vars.isReversedBool}">
        <NuxtImg
          v-if="vars.photoImage && !vars.videoUrl"
          :src="vars.photoImage"
           class="tw-h-auto tw-w-full"
        />
        <library-video-embed v-if="vars.videoUrl"
          :video-url="vars.videoUrl"
          title="Video Popup"
          :is-popup="true"
          :cover-photo="vars.photoImage"
        />
        <NuxtImg v-if="vars.thumbnailImage"
          :src="vars.thumbnailImage"
          class="tw-absolute tw-h-auto tw-w-1/2 tw-transform"
          :class="{'tw-translate-y-1/4 tw-border-r-8 tw-border-t-8 -translate-x-1/4 -tw-left-10 tw-bottom-0': vars.thumbnailCornerText === 'bottom-left'},
                  {'tw-translate-y-1/4 tw-border-l-8 tw-border-t-8 -tw-right-10 -translate-x-1/4 tw-bottom-0': vars.thumbnailCornerText === 'bottom-right'},
                  {'tw-translate-y-1/4 tw-border-r-8 tw-border-b-8 -translate-x-1/4 -tw-left-10 -tw-top-1/4': vars.thumbnailCornerText === 'top-left'},
                  {'tw-translate-y-1/4 tw-border-l-8 tw-border-b-8 -tw-right-10 -translate-x-1/4 -tw-top-1/4': vars.thumbnailCornerText === 'top-right'}"
          :style="`border-color: var(--c-${vars.backgroundColor})`"
        />
      </div>
      <div
        v-html="vars.titleHtml"
        class="tw-flex tw-flex-row tw-flex-wrap xl:tw-col-span-7 xl:tw-row-start-1 xl:tw-self-end"
      ></div>
      <div class="tw-flex tw-flex-col xl:tw-col-span-7 xl:tw-row-start-2 xl:tw-self-start tw-max-w-[750px]">
        <p
          class="xl:tw-whitespace-pre-line"
          v-for="(item, index) in descriptions"
          :key="`why-box-description-${index}`"
        >
          {{ item.description }}
        </p>
        <div class="tw-grid tw-gap-x-10 tw-gap-y-2 tw-pt-5 sm:tw-mr-auto sm:tw-grid-cols-2">
          <div class="tw-flex tw-flex-col tw-gap-y-2">
            <div
              v-if="featuresColumns1"
              v-for="(feature, index) in featuresColumns1"
              :key="index"
              class="tw-flex tw-items-center tw-gap-3"
            >
              <svg
                class="tw-shrink-0"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.5501 18L3.8501 12.3L5.2751 10.875L9.5501 15.15L18.7251 5.97498L20.1501 7.39998L9.5501 18Z"
                  fill="var(--c-primary)"
                />
              </svg>
              <h6>
                {{ feature.title }}
              </h6>
            </div>
          </div>
          <div class="tw-flex tw-flex-col tw-gap-y-2">
            <div
              v-if="featuresColumns2"
              v-for="(feature, index) in featuresColumns2"
              :key="index"
              class="tw-flex tw-items-center tw-gap-3"
            >
              <svg
                class="tw-shrink-0"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.5501 18L3.8501 12.3L5.2751 10.875L9.5501 15.15L18.7251 5.97498L20.1501 7.39998L9.5501 18Z"
                  fill="var(--c-primary)"
                />
              </svg>
              <h6>
                {{ feature.title }}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'WhyBox',

  mixins: [ComponentMixin],
  
  computed: {
    featuresColumns1() {
      return this.groupedVariables.column_1_features;
    },
    
    featuresColumns2() {
      return this.groupedVariables.column_2_features;
    },
    
    descriptions() {
      return this.groupedVariables.descriptions;
    },
  },

});
</script>
