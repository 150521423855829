<template>
  <section class="n-section-primary" :style="`background-color: var(--c-${vars.backgroundColor})`">
    <div class="tw-w-full tw-text-black">
      <div
        v-html="vars.titleHtml"
        class="tw-mb-6 tw-mr-auto tw-flex tw-items-start tw-justify-start tw-gap-3 tw-text-start md:tw-mb-10"
      ></div>
      <div
        class="tw-mx-auto tw-flex tw-w-full tw-flex-col tw-bg-white md:tw-w-[80%] lg:tw-w-full"
        :class="vars.isReversedBool ? 'lg:tw-flex-row-reverse' : 'lg:tw-flex-row'"
        style="border-radius: var(--rounded)"
      >
        <div class="tw-w-full lg:tw-max-w-[515px]">
          <LibraryImage
            :w-ratio="1"
            :h-ratio="1.45"
            :src="vars.photoImage"
            style="border-top-left-radius: var(--rounded); border-bottom-left-radius: var(--rounded)"
          />
        </div>
        <div class="tw-flex tw-w-full tw-p-6 md:tw-p-10 xl:tw-px-20">
          <h3>{{ vars.secondaryTitleText }}</h3>

          <library-form
            v-if="form"
            :form="form"
            version="v2"
            :component="component"
            :is-form-name-visible="vars.isFormNameVisibleBool"
          ></library-form>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'GuideSection',

  mixins: [ComponentMixin],

  data() {
    return {
      form: null,
    };
  },

  created() {
    this.form = this.parseFormV2(this.vars.guideForm);
  },
});
</script>
