<template>
  <section class="n-section-primary" :style="`background-color: var(--c-${vars.backgroundColor})`">
    <div class="tw-mb-10 tw-flex tw-w-full tw-flex-wrap tw-justify-start">
      <div
        class="tw-flex tw-flex-wrap tw-justify-center tw-gap-3 tw-text-center lg:tw-gap-4"
        v-html="vars.titleHtml"
      ></div>
    </div>
    <div
      class="tw-grid tw-w-full tw-grid-cols-3 tw-grid-rows-2 tw-gap-3 sm:tw-grid-cols-6 sm:tw-gap-7 lg:tw-grid-cols-11"
    >
      <template v-for="(location, index) in locations" :key="`ourlocations-location-${index}`">
        <div
          v-if="location.wide"
          class="tw-relative tw-col-span-3 tw-row-span-2 tw-overflow-hidden !tw-p-0 tw-transition-all tw-duration-300 hover:tw-scale-[101%] sm:tw-col-span-6 lg:tw-col-span-5"
          style="border-radius: var(--rounded)"
          @click="setLocation(location)"
        >
          <nuxt-link v-if="location.link" :to="location.link" :external="true">
            <LibraryImage class="tw-h-full" :design-width="618" :design-height="540" :src="location.photo" />
            <div class="tw-absolute tw-inset-0 tw-bg-gradient-to-t tw-from-black/50 tw-from-5% tw-opacity-80"></div>
            <div class="tw-absolute tw-bottom-7 tw-left-6 tw-z-10">
              <h4 class="tw-text-white">{{ location.title }}</h4>
            </div>
          </nuxt-link>
          <template v-else>
            <LibraryImage class="tw-h-full" :w-ratio="618" :h-ratio="540" :src="location.photo" />
            <div
              class="tw-absolute tw-inset-0 tw-z-50 tw-bg-[var(--c-primary)] tw-text-white tw-opacity-0 tw-duration-500 hover:tw-opacity-30"
            ></div>
            <div class="tw-absolute tw-inset-0 tw-bg-gradient-to-t tw-from-black/50 tw-from-5% tw-opacity-70"></div>
            <div class="tw-absolute tw-bottom-7 tw-left-6 tw-z-10">
              <h4 class="tw-text-white">{{ location.title }}</h4>
            </div>
          </template>
        </div>
        <div
          class="tw-relative tw-col-span-3 tw-row-span-1 tw-overflow-hidden !tw-p-0 tw-transition-all tw-duration-300 hover:tw-scale-[101%]"
          style="border-radius: var(--rounded)"
          @click="setLocation(location)"
          v-else
        >
          <nuxt-link v-if="location.link" :to="location.link" :external="true">
            <LibraryImage class="tw-h-full lg:!tw-absolute" :h-ratio="3" :w-ratio="4" :src="location.photo" />
            <div
              class="tw-absolute tw-inset-0 tw-z-50 tw-bg-[var(--c-primary)] tw-text-white tw-opacity-0 tw-duration-500 hover:tw-opacity-30"
            ></div>
            <div class="tw-absolute tw-inset-0 tw-bg-gradient-to-t tw-from-black/50 tw-from-5% tw-opacity-70"></div>
            <div class="tw-absolute tw-bottom-7 tw-left-6 tw-z-10">
              <h4 class="tw-text-white">{{ location.title }}</h4>
            </div>
          </nuxt-link>
          <template v-else>
            <LibraryImage class="tw-h-full" :h-ratio="3" :w-ratio="4" :src="location.photo" />
            <div class="tw-absolute tw-inset-0 tw-bg-gradient-to-t tw-from-black/50 tw-from-5% tw-opacity-70"></div>
            <div
              class="tw-absolute tw-inset-0 tw-z-50 tw-bg-[var(--c-primary)] tw-text-white tw-opacity-0 tw-duration-500 hover:tw-opacity-30"
            ></div>
            <div class="tw-absolute tw-bottom-7 tw-left-6 tw-z-10">
              <h4 class="tw-text-white">{{ location.title }}</h4>
            </div>
          </template>
        </div>
      </template>
    </div>
    <CommonPopup v-if="selectedLocation?.popup_photo" custom-width="95%" :config="popupConfig">
      <template #content v-if="selectedFaqs && popupConfig.isActive">
        <div class="tw-grid tw-grid-cols-5 tw-items-center lg:tw-grid-cols-11">
          <div class="tw-col-span-5">
            <LibraryImage :src="selectedLocation?.popup_photo" class="tw-h-full"></LibraryImage>
          </div>
          <div class="tw-col-span-1"></div>
          <div class="tw-col-span-5">
            <div
              v-html="vars.titleHtml"
              class="tw-mb-3 tw-flex tw-w-full tw-flex-row tw-flex-wrap tw-gap-x-4 tw-text-start tw-text-black md:tw-mb-6"
            ></div>
            <AccordionList :items="selectedFaqs" />
          </div>
        </div>
      </template>
    </CommonPopup>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
import AccordionList from '~/components/theme5/components/AccordionList.vue';

export default defineNuxtComponent({
  name: 'OurLocations',

  mixins: [ComponentMixin],

  components: {
    AccordionList,
  },

  data() {
    return {
      selectedLocation: null,
      selectedFaqs: null,
      popupConfig: {
        isActive: false,
      },
    };
  },

  methods: {
    setLocation(location) {
      const faqs = this.getFaqs(location.id);

      if (faqs.length === 0) {
        this.selectedLocation = null;
        this.popupConfig.isActive = false;
      } else {
        this.selectedFaqs = faqs;
        this.selectedLocation = location;
        this.popupConfig.isActive = true;
      }
    },
    getFaqs(id) {
      const faqs = this.groupedVariables.faqs.filter((f) => f.location_id === id);
      return faqs;
    },
  },
  computed: {
    locations() {
      const sortedLocations = this.groupedVariables.locations.sort((a, b) => b.wide - a.wide);
      return sortedLocations;
    },
  },
});
</script>
