<template>
  <section class="n-section-primary" :style="`background-color: var(--c-${vars.backgroundColor})`">
    <div class="tw-flex tw-w-full tw-max-w-sm tw-flex-col tw-items-center tw-text-black md:tw-max-w-none">
      <div
        v-html="vars.titleHtml"
        class="tw-mb-3 tw-flex tw-w-full tw-flex-row tw-flex-wrap tw-gap-x-4 tw-text-start tw-text-black md:tw-mb-6"
      ></div>
      <div class="tw-flex tw-w-full tw-flex-row tw-items-center tw-justify-center">
        <div
          data-w-id="080a9ca2-7a2d-2ca3-3b22-f42b27569daf"
          class="left-arrow tw-hidden md:tw-flex"
          @click="() => swiper.slidePrev()"
        >
          <svg width="14" height="24" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 2L2 12L12 22" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>
        <div class="tw-w-full">
          <Swiper
            effect="fade"
            :clickable="true"
            :modules="[SwiperGrid]"
            @slideChange="onSlideChange"
            :watch-overflow="true"
            :breakpoints="{
              320: {
                slidesPerView: 2,
                spaceBetween: 30,
                grid: { rows: 2, fill: 'row' },
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: 6,
                spaceBetween: 10,
              },
            }"
            :spaceBetween="30"
            @swiper="onSwiper"
            class="tw-max-w-sm md:tw-max-w-none"
          >
            <SwiperSlide
              v-for="(post, index) in instagram_posts?.content_list"
              :key="`socialgallery-post-${index}`"
              class="tw-group tw-h-full tw-w-full hover:tw-cursor-pointer"
            >
              <div
                v-if="post.is_deleted === false && post.is_hidden === false"
                class="no-hover shine-effect tw-group tw-relative tw-basis-1/3 tw-overflow-hidden tw-transition-all tw-duration-500"
              >
                <LibraryImage
                  :w-ratio="9"
                  :h-ratio="16"
                  :src="post.thumbnail_url"
                  style="border-radius: var(--rounded)"
                />

                <video
                  v-if="post.media_type === 'VIDEO'"
                  :src="post.media_url"
                  class="tw-absolute tw-inset-0 tw-h-full tw-w-full tw-object-cover tw-opacity-0 tw-transition-opacity tw-duration-500 group-hover:tw-opacity-100"
                  style="border-radius: var(--rounded)"
                  muted
                  loop
                  autoplay
                  playsinline
                ></video>
                <!-- <div
                  class="tw-absolute tw-inset-0 tw-flex tw-flex-col-reverse tw-items-center tw-bg-[--c-primary] tw-text-white tw-opacity-0 tw-duration-500 group-hover:tw-opacity-80"
                ></div> -->
                <div
                  class="tw-absolute tw-inset-0 tw-z-50 tw-flex tw-cursor-pointer tw-flex-row tw-items-start tw-justify-end tw-p-3 tw-text-white tw-opacity-0 tw-duration-500 group-hover:tw-opacity-100 lg:tw-p-6"
                  @click="openPopup(post, true)"
                >
                  <svg viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="tw-w-6 lg:tw-w-8">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M21.375 21.375H4.125C3.71016 21.375 3.375 21.0398 3.375 20.625V3.375C3.375 2.96016 3.71016 2.625 4.125 2.625H12.5625C12.6656 2.625 12.75 2.70937 12.75 2.8125V4.125C12.75 4.22812 12.6656 4.3125 12.5625 4.3125H5.0625V19.6875H20.4375V12.1875C20.4375 12.0844 20.5219 12 20.625 12H21.9375C22.0406 12 22.125 12.0844 22.125 12.1875V20.625C22.125 21.0398 21.7898 21.375 21.375 21.375ZM18.8173 4.66713L17.5938 3.4437C17.4837 3.33354 17.5493 3.1437 17.704 3.12495L21.9087 2.63276C22.0282 2.6187 22.1313 2.71948 22.1173 2.84135L21.6251 7.04604C21.6063 7.20073 21.4165 7.26635 21.3063 7.1562L20.0782 5.92807L14.0735 11.9328C14.0009 12.0054 13.8813 12.0054 13.8087 11.9328L12.8149 10.939C12.7423 10.8664 12.7423 10.7468 12.8149 10.6742L18.8173 4.66713Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </div>
            </SwiperSlide>
            <div class="controls !tw-mt-8 md:!tw-hidden">
              <div
                data-w-id="080a9ca2-7a2d-2ca3-3b22-f42b27569daf"
                class="slide-left-arrow w-slider-arrow-left"
                :isDisabled="!canPaginateBack"
                @click="() => swiper.slidePrev()"
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M16.011 3.49956C16.1418 3.6335 16.2151 3.81332 16.2151 4.00056C16.2151 4.18781 16.1418 4.36762 16.011 4.50156L8.70149 12.0001L16.011 19.4971C16.1418 19.631 16.2151 19.8108 16.2151 19.9981C16.2151 20.1853 16.1418 20.3651 16.011 20.4991C15.9474 20.5644 15.8714 20.6163 15.7874 20.6517C15.7034 20.6872 15.6132 20.7054 15.522 20.7054C15.4308 20.7054 15.3406 20.6872 15.2566 20.6517C15.1726 20.6163 15.0966 20.5644 15.033 20.4991L7.25999 12.5236C7.12347 12.3835 7.04706 12.1957 7.04706 12.0001C7.04706 11.8045 7.12347 11.6166 7.25999 11.4766L15.033 3.50106C15.0966 3.43575 15.1726 3.38383 15.2566 3.34839C15.3406 3.31294 15.4308 3.29468 15.522 3.29468C15.6132 3.29468 15.7034 3.31294 15.7874 3.34839C15.8714 3.38383 15.9474 3.43575 16.011 3.50106V3.49956Z"
                    fill="#6EADAB"
                  />
                </svg>
                <div class="slide-circle slider-circle-transform"></div>
              </div>
              <div
                data-w-id="080a9ca2-7a2d-2ca3-3b22-f42b27569db1"
                class="slide-right-arrow w-slider-arrow-right"
                :isDisabled="!canPaginateNext"
                @click="() => swiper.slideNext()"
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.98901 3.49956C7.85817 3.6335 7.78491 3.81332 7.78491 4.00056C7.78491 4.18781 7.85817 4.36762 7.98901 4.50156L15.2985 12.0001L7.98901 19.4971C7.85817 19.631 7.78491 19.8108 7.78491 19.9981C7.78491 20.1853 7.85817 20.3651 7.98901 20.4991C8.0526 20.5644 8.12864 20.6163 8.21263 20.6517C8.29661 20.6872 8.38685 20.7054 8.47801 20.7054C8.56917 20.7054 8.65941 20.6872 8.74339 20.6517C8.82738 20.6163 8.90342 20.5644 8.96701 20.4991L16.74 12.5236C16.8765 12.3835 16.9529 12.1957 16.9529 12.0001C16.9529 11.8045 16.8765 11.6166 16.74 11.4766L8.96701 3.50106C8.90342 3.43575 8.82738 3.38383 8.74339 3.34839C8.65941 3.31294 8.56917 3.29468 8.47801 3.29468C8.38685 3.29468 8.29661 3.31294 8.21263 3.34839C8.12864 3.38383 8.0526 3.43575 7.98901 3.50106V3.49956Z"
                    fill="#6EADAB"
                  />
                </svg>
                <div class="slide-circle right"></div>
              </div>
            </div>
          </Swiper>
        </div>
        <div
          data-w-id="080a9ca2-7a2d-2ca3-3b22-f42b27569daf"
          class="right-arrow tw-hidden md:tw-flex"
          @click="() => swiper.slideNext()"
        >
          <svg width="14" height="24" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 2L12 12L2 22" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>
      </div>
      <MotionGroup :preset="vars.animationText ? vars.animationText : 'popVisible'" :duration="400">
        <popup v-if="selectedPost && popupConfig.isActive" :config="popupConfig" custom-width="60%">
          <template #content>
            <div class="popup-container tw-grid tw-grid-cols-12 max-lg:tw-pt-6 lg:tw-overflow-y-hidden">
              <!-- Video Section -->
              <div class="video-section tw-relative tw-col-span-12 lg:tw-col-span-5">
                <div class="tw-aspect-[9/16] tw-h-full tw-w-full">
                  <video
                    v-if="selectedPost.media_type?.toLowerCase() === 'video'"
                    :src="selectedPost.media_url"
                    class="tw-absolute tw-inset-0 tw-h-full tw-w-full tw-object-cover"
                    style="border-radius: var(--rounded)"
                    muted
                    autoplay
                    loop
                    playsinline
                    controls
                  ></video>
                  <img
                    v-else
                    :src="selectedPost.thumbnail_url"
                    alt="Post thumbnail"
                    class="tw-absolute tw-inset-0 tw-h-full tw-w-full tw-object-cover"
                  />
                </div>
              </div>

              <!-- Caption Section -->
              <div
                class="tw-col-span-12 tw-flex tw-flex-col tw-overflow-y-auto max-lg:tw-mt-4 lg:tw-col-span-5 lg:tw-p-8"
              >
                <div class="tw-flex tw-flex-col">
                  <div class="tw-w-24">
                    <LibraryImage :src="company.logo" :h-ratio="1" :w-ratio="1.2" />
                  </div>
                  <!-- <h4>{{ company.name }}</h4> -->
                </div>
                <br />
                <p class="tw-mb-4">{{ selectedPost.caption }}</p>
                <nuxt-link :to="selectedPost.permalink" target="_blank" class="n-link tw-cursor-pointer">
                  <svg
                    fill="var(--c-primary)"
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="28px"
                    height="28px"
                    viewBox="0 0 169.063 169.063"
                    xml:space="preserve"
                  >
                    <g>
                      <path
                        d="M122.406,0H46.654C20.929,0,0,20.93,0,46.655v75.752c0,25.726,20.929,46.655,46.654,46.655h75.752
		c25.727,0,46.656-20.93,46.656-46.655V46.655C169.063,20.93,148.133,0,122.406,0z M154.063,122.407
		c0,17.455-14.201,31.655-31.656,31.655H46.654C29.2,154.063,15,139.862,15,122.407V46.655C15,29.201,29.2,15,46.654,15h75.752
		c17.455,0,31.656,14.201,31.656,31.655V122.407z"
                      />
                      <path
                        d="M84.531,40.97c-24.021,0-43.563,19.542-43.563,43.563c0,24.02,19.542,43.561,43.563,43.561s43.563-19.541,43.563-43.561
		C128.094,60.512,108.552,40.97,84.531,40.97z M84.531,113.093c-15.749,0-28.563-12.812-28.563-28.561
		c0-15.75,12.813-28.563,28.563-28.563s28.563,12.813,28.563,28.563C113.094,100.281,100.28,113.093,84.531,113.093z"
                      />
                      <path
                        d="M129.921,28.251c-2.89,0-5.729,1.17-7.77,3.22c-2.051,2.04-3.23,4.88-3.23,7.78c0,2.891,1.18,5.73,3.23,7.78
		c2.04,2.04,4.88,3.22,7.77,3.22c2.9,0,5.73-1.18,7.78-3.22c2.05-2.05,3.22-4.89,3.22-7.78c0-2.9-1.17-5.74-3.22-7.78
		C135.661,29.421,132.821,28.251,129.921,28.251z"
                      />
                    </g>
                  </svg>
                </nuxt-link>
              </div>
            </div>
          </template>
        </popup>
      </MotionGroup>
    </div>
  </section>
</template>
<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
import Popup from '~/components/common/popup/Popup.vue';
import 'swiper/css';

export default defineNuxtComponent({
  name: 'SocialGallery',

  components: { Popup },

  mixins: [ComponentMixin],

  data() {
    return {
      swiper: null,
      canPaginateBack: true,
      canPaginateNext: true,
      selectedPost: null,
      popupConfig: {
        isActive: false,
      },
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
      isVisible: false,
      instagram_posts: null,
    };
  },

  created() {
    this.instagram_posts = this.parseFormV2(this.vars.socialInstagramPosts);
  },

  methods: {
    openPopup(post = null, value = false) {
      if (post) {
        this.selectedPost = post;
        this.popupConfig.isActive = value;
      }
    },

    toggleSocialPopup(post = null, value = false) {
      if (post.popup_title) {
        this.selectedPost = post;
        this.popupConfig.isActive = value;
      }
    },
    onSwiper(swiper) {
      this.swiper = swiper;
    },

    onSlideChange() {
      const isAtStart = this.swiper.isBeginning;
      const isAtEnd = this.swiper.isEnd;
      if (isAtStart) this.canPaginateBack = false;
      else this.canPaginateBack = true;
      if (isAtEnd) this.canPaginateNext = false;
      else this.canPaginateNext = true;
    },
  },

  mounted() {
    this.onSlideChange();
  },
});
</script>

<style scoped>
.left-arrow,
.right-arrow {
  position: absolute;
}

.left-arrow {
  left: 10px;
  z-index: 10;
}

.right-arrow {
  right: 10px;
  z-index: 10;
}

.left-arrow svg path,
.right-arrow svg path {
  transition: all 0.3s;
}

.left-arrow:hover,
.right-arrow:hover {
  cursor: pointer;
}

.left-arrow:hover svg path,
.right-arrow:hover svg path {
  stroke: var(--c-primary);
}

::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: var(--c-primary);
}
::-webkit-scrollbar-thumb:window-inactive {
  display: none;
}

@media (min-width: 1080px) {
  .left-arrow,
  .right-arrow {
    position: relative;
  }

  .left-arrow {
    padding-right: 18px;
  }

  .right-arrow {
    padding-left: 18px;
  }
}

@media (min-width: 700px) and (max-width: 1400px) {
  ::v-deep(.pm-modal-wrapper) {
    width: 95% !important;
  }
}

@media (min-width: 1401px) {
  ::v-deep(.pm-modal-wrapper) {
    width: 60% !important;
  }
}
</style>
