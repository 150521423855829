<template>
  <section class="n-section-primary" :style="`background-color: var(--c-${vars.backgroundColor})`">
    <div class="tw-text-black">
      <div
        v-html="vars.titleHtml"
        class="tw-mb-10 tw-mr-auto tw-flex tw-items-start tw-justify-start tw-gap-3 tw-text-start"
      ></div>
      <div class="tw-flex tw-flex-col tw-gap-6 lg:tw-flex-row">
        <div
          v-for="(reason, index) in reasons"
          :key="`whysection-reason-${index}`"
          class="tw-flex tw-w-full tw-flex-col tw-items-center tw-gap-4 tw-bg-white tw-p-7 tw-text-center"
        >
          <nuxt-img :src="reason.icon" class="tw-max-w-16 lg:tw-w-20 lg:tw-max-w-none" />
          <h4 class="tw-mb-4 tw-h-1/6">{{ reason.title }}</h4>
          <p class="tw-line-clamp-[10]">
            {{ reason.description }}
          </p>
          <nuxt-link
            :to="reason.button_link"
            :class="`n-${reason.button_type} tw-mt-auto`"
            style="color: var(--c-primary)"
            ><h5>{{ reason.button_label }}</h5></nuxt-link
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'WhySection',

  mixins: [ComponentMixin],

  computed: {
    reasons() {
      return this.groupedVariables.reasons;
    },
  },
});
</script>
